@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import '@fortawesome/fontawesome-free/css/all.css';
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

:root {
    --main_primery_color: #c99201;
    --title-color: #303030;
    --sub-title-color: #6161661;
}

body {
    font-family: "Inter", sans-serif !important;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.container-fluid {
    padding: 0;
    margin: 0;
}

.inner_section_dating img {
    max-width: 250px;
    margin-bottom: 12px;
}

.main_section_dating {
    background-image: linear-gradient(#6a531724, #1c002f), url(./assets/images/hero-section-top_2a.jpg);
    width: 100%;
    height: 100vh;
    align-content: center;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.inner_section_dating {
    MAX-WIDTH: 1024px;
    text-align: center;
    margin: auto;
    color: white;
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.inner_section_dating a svg {
    display: none;

    opacity: 0;
}

.inner_section_dating a:hover svg {
    display: block;
    opacity: 1;
}


.inner_section_dating .login {
    position: absolute;
    top: 20px;
    right: 20px;
    color: white;
    background: transparent;
    font-weight: 600;
    line-height: 25px;
    background-color: transparent;
    border-radius: 30px 30px 30px 30px;
    padding: 5px 30px 5px 30px;
    border: 1px solid white;
    height: 50px;
    align-items: center;
    max-width: 145px;
    font-size: 16px;
    box-shadow: none;
}

.inner_section_dating .login:hover {
    box-shadow: 0px 8px 10px 0px rgba(247, 177, 0, 0.2);
    background-color: var(--main_primery_color);
    border: none;
    border: 1px solid var(--main_primery_color);
}

.inner_section_dating h2 {
    color: #FFFFFF;
    font-size: 60px;
    font-weight: 600;
    line-height: initial;
}

div#accordionExample h3 {
    text-align: center;
    font-weight: 600;
    color: white;
    margin-bottom: 30px;
    font-size: 36px;
}

select.role_select {
    width: 100%;
    padding: 10px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 25px;
    font-size: 16px;
    color: rgba(51, 51, 51, 0.36);
    background-color: #ffffff;
    text-align: center;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}


/* Style for text and password inputs, as well as select box */
/* input[type="text"],
input[type="password"],
select.role_select {
    width: 100%;
    padding: 10px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 25px;
    font-size: 16px;
    color: #333;
    background-color: #f7f7f7;
    text-align: center;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

select.role_select {
    text-align-last: center;
}

select.role_select:focus,
input[type="text"]:focus,
input[type="password"]:focus {
    border-color: #a4a4a4;
    background-color: #f0f0f0;
} */


.inner_section_dating a {
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    background-color: var(--main_primery_color);
    border-radius: 30px 30px 30px 30px;
    box-shadow: 0px 8px 10px 0px #c9900142;
    padding: 10px 40px 10px 40px;
    color: white;
    text-decoration: none;
    position: relative;
    margin-top: 10px;
    display: flex;
    max-width: 265px;
    justify-content: center;
    gap: 10px;
    align-items: center;
    width: 100%;
    height: 50px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, width .15s ease-in-out;


}

.inner_section_dating p {
    font-size: 22px;
    line-height: 20px;
    color: white;
    font-weight: 400;
}

.dating_connection {
    padding: 80px 0px;
}

.dating_connection h3 {
    font-size: 36px;
    color: var(--title-color);
}

.dating_connection p {
    font-size: 16px;
    color: var(--sub-title-color);
}

.daing_content {
    max-width: 470px;
}


.dating_connection img {
    width: 100%;
    max-width: 500px;
    max-height: 650px;
    object-fit: cover;
}

/* .dating_connection .row:first-child .dating_connection_img img {
    box-shadow: 24px 24px 0px #ff0281;
   
} */

/* .dating_connection .row:nth-child(3) .dating_connection_img img {
    box-shadow: 24px 24px 0px #ff0281;
   
}
.dating_connection .row:nth-child(5) .dating_connection_img img {
    box-shadow: 24px 24px 0px #ff0281;
   
}


.dating_connection .row:nth-child(2) .dating_connection_img img {
    box-shadow: 24px -24px 0px #ff0281;
   
}
.dating_connection .row:nth-child(4) .dating_connection_img img {
    box-shadow: 24px -24px 0px #ff0281;
   
} */



.dating_connection .row {
    align-items: center;
}

.Our_Story_section {
    background-image: url(./assets/images/ourstory.jpg);
    width: 100%;
    align-content: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 350px;
    margin-bottom: 60px;

}

.inner_story_row {
    width: 100%;
    height: auto;
    backdrop-filter: blur(5px);
    min-height: 350px;
    align-content: center;
}

.our_content_col {
    max-width: 900px;
    text-align: center;
    margin: auto;
    color: white;
}

.our_content_col h3 {
    font-size: 36px;
    font-weight: 600;
}

.our_content_col p {
    font-size: 16px;
}


/* ========================testimonial css============================ */

.testimonial-slider {
    position: relative;
    width: 80%;
    margin: auto;
    overflow: hidden;
    text-align: center;
}

.testimonial {
    display: none;
    padding: 20px;
    transition: opacity 0.5s ease;
}

.testimonial.active {
    display: block;
}

.dots {
    text-align: center;
    margin-top: 20px;
}

.dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: gray;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
}

.dot.active {
    background-color: black;
}

.testimonial img {
    max-width: 100px;
    border-radius: 119px;
    height: 100px;
    object-fit: cover;
}

.dating_connection_img {
    margin: auto;
    text-align: center;
}

.daing_content {
    max-width: 470px;
    margin: auto;

}

.store_links {
    display: flex;
    gap: 10px;
}

.store_links img {
    max-width: 136px;
    border-radius: 8px;
}

.store_links_images {
    position: absolute;
    border: 30px;
    left: 0;
}

.store_links_images {
    position: absolute;
    bottom: 30px;
    left: 64px;
}

.store_links_images img {
    max-width: 136px;
    border-radius: 8px;
    margin-left: 10px;
}

.cotent_col_slider h4 {
    font-size: 36px;
    font-weight: 600;
}

.cotent_col_slider {
    max-width: 650px;
    margin: 20px auto;
}

div#accordionExample {
    max-width: 1024px;
    margin: auto;
}

.accodian_main_section {
    padding: 60px 0px;
    background: #000;
    margin-top: 60px;
}

.accordion-item {
    margin-bottom: 30px;
    border-radius: 10px !important;
    overflow: hidden;

}

.accordion-button:not(.collapsed) {
    background: transparent;
    color: var(--main_primery_color);
}

.accordion-button:not(.collapsed)::after {
    filter: invert(1);
}

.accordion-button:focus {
    box-shadow: none !important;
}

a.view_more {
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    background-color: var(--main_primery_color);
    border-radius: 30px 30px 30px 30px;
    box-shadow: 0px 8px 10px 0px #c9900142;
    padding: 15px 40px 15px 40px;
    text-decoration: none;
    color: white;


}

form a {
    text-decoration: none;
}

.view_btn {
    text-align: center;
}

button.accordion-button.collapsed {
    color: #fff;
    background: transparent;
    border-bottom: 1px solid #ffffff3d;
    color: #fff;
}

.footer_section {
    text-align: center;
    background: black;
    padding: 20px 60px;
    border-top: 1px solid #dddddd2b;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer_logo img {
    max-width: 274px;
    width: 100%;

}

.accordion-item {
    background: transparent;
    color: white;
    border: none;

}

button.accordion-button {
    font-size: 20px;
    font-weight: 500;
}

.accordion-body p {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: white;
}


/* ================================login =============================== */




section .container .user {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
}

section .container .user .imgBx {
    position: relative;
    width: 50%;
    height: 100%;
    background: #ff0;
    transition: 0.5s;
}

section .container .user .imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

section .container .user .formBx {
    position: relative;
    width: 50%;
    height: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    transition: 0.5s;
}

section .container .user .formBx form h2 {
    font-size: 36px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
    color: var(--title-color);
}

section .container .user .formBx form input {
    position: relative;
    width: 100%;
    padding: 10px 24px;
    background: #fff;
    color: var(--sub-title-color);
    border: 1PX solid #e3e3e3;
    margin: 8px 0;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 300;
    border-radius: 30px;
}

section .container .user .formBx form input[type='submitt'],
button.login_button {
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    background-color: var(--main_primery_color);
    border-radius: 30px 30px 30px 30px;
    box-shadow: 0px 8px 10px 0px rgba(247, 0, 119.00000000000011, 0.2);
    padding: 10px 40px 10px 40px;
    color: white;
    text-decoration: none;
    position: relative;
    margin-top: 10px;
    margin: auto;
    display: block;
    border: none;
    cursor: pointer;
    width: 100%;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, width .15s ease-in-out;
}

.formBx form {
    max-width: 450px;
}

section .container .user .formBx form .signup {
    position: relative;
    margin-top: 20px;
    font-size: 12px;
    letter-spacing: 1px;
    color: var(--sub-title-color);
    text-transform: uppercase;
    font-weight: 300;
    font-size: 14px;
    display: flex;
    gap: 8px;
}

section .container .user .formBx form .signup div {
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    color: var(--main_primery_color);
}

section .container .signupBx {
    pointer-events: none;
}

section .container.active .signupBx {
    pointer-events: initial;
}

section .container .signupBx .formBx {
    left: 100%;
}

section .container.active .signupBx .formBx {
    left: 0;
}

section .container .signupBx .imgBx {
    left: -100%;
}

section .container.active .signupBx .imgBx {
    left: 0%;
}

section .container .signinBx .formBx {
    left: 0%;
}

section .container.active .signinBx .formBx {
    left: 100%;
}

section .container .signinBx .imgBx {
    left: 0%;
}

section .container.active .signinBx .imgBx {
    left: -100%;
}

img.login_logo {
    width: 274px;
    margin: auto;
    display: block;
    margin-bottom: 20px;
}


@media (max-width: 991px) {


    section .container .imgBx {
        display: none;
    }

    section .container .user .formBx {
        width: 100%;
    }
}


/* ===================================================== */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

body {
    font-family: "Inter", sans-serif;
    background-color: #f4f6fa;
}

.inner_var_Bar img {
    max-width: 200px;
}


.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 78px;
    background: var(--main_primery_color);
    padding: 6px 14px;
    z-index: 99;
    transition: all 0.5s ease;
}

.sidebar.open {
    width: 200px;
}

.sidebar .logo-details {
    height: 60px;
    display: flex;
    align-items: center;
    position: relative;
}

.sidebar .logo-details .icon {
    opacity: 0;
    transition: all 0.5s ease;
}

.sidebar .logo-details .logo_name {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    opacity: 0;
    transition: all 0.5s ease;
}

.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
    opacity: 1;
}

.sidebar .logo-details #btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 22px;
    transition: all 0.4s ease;
    font-size: 23px;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s ease;
}

.sidebar.open .logo-details #btn {
    text-align: right;
}

.sidebar i {
    color: #fff;
    height: 60px;
    min-width: 50px;
    font-size: 28px;
    text-align: center;
    line-height: 60px;
}

.sidebar .nav-list {
    margin-top: 20px;
    height: 100%;
    padding: 0;
}

.sidebar li {
    position: relative;
    margin: 8px 0;
    list-style: none;
}

.sidebar li .tooltip {
    position: absolute;
    top: -20px;
    left: calc(100% + 15px);
    z-index: 3;
    background: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 400;
    opacity: 0;
    white-space: nowrap;
    pointer-events: none;
    transition: 0s;
}

.sidebar li:hover .tooltip {
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
    top: 50%;
    transform: translateY(-50%);
}

.sidebar.open li .tooltip {
    display: none;
}

.sidebar input {
    font-size: 15px;
    color: #FFF;
    font-weight: 400;
    outline: none;
    height: 50px;
    width: 100%;
    width: 50px;
    border: none;
    border-radius: 12px;
    transition: all 0.5s ease;
    background: #303030;
}

.sidebar.open input {
    padding: 0 20px 0 50px;
    width: 100%;
}

.sidebar .fa-search {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: 22px;
    background: #303030;
    color: #FFF;
}

.sidebar.open .fa-search:hover {
    background: #1d1b31;
    color: #FFF;
}

.sidebar .fa-search:hover {
    background: #FFF;
    color: #11101d;
}

.sidebar li a {
    display: flex;
    height: 100%;
    width: 100%;
    border-radius: 12px;
    align-items: center;
    text-decoration: none;
    transition: all 0.4s ease;
    background: #d4d3d454;
}

.sidebar li a:hover {
    background: #FFF;
}

.sidebar li a .links_name {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    transition: 0.4s;
}

.sidebar.open li a .links_name {
    opacity: 1;
    pointer-events: auto;
}

.sidebar li a:hover .links_name,
.sidebar li a:hover i {
    transition: all 0.5s ease;
    color: #11101D;
}

.sidebar li i {
    height: 50px;
    line-height: 50px;
    font-size: 24px;
    border-radius: 12px;
}

.sidebar li.profile {
    position: fixed;
    height: 60px;
    width: 78px;
    left: 0;
    bottom: -8px;
    padding: 10px 14px;
    background: #1d1b31;
    transition: all 0.5s ease;
    overflow: hidden;
}

.sidebar.open li.profile {
    width: 250px;
}

.sidebar li .profile-details {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.sidebar li img {
    height: 45px;
    width: 45px;
    object-fit: cover;
    border-radius: 6px;
    margin-right: 10px;
}

.sidebar li.profile .name,
.sidebar li.profile .job {
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    white-space: nowrap;
}

.sidebar li.profile .job {
    font-size: 12px;
}

.sidebar .profile #log_out {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: #11101D;
    width: 100%;
    height: 60px;
    line-height: 60px;
    border-radius: 0px;
    transition: all 0.5s ease;
}

.sidebar.open .profile #log_out {
    width: 50px;
    background: none;
}

.home-section {
    position: relative;
    background: #fff;
    min-height: 100vh;
    top: 0;
    left: 78px;
    width: calc(100% - 78px);
    transition: all 0.5s ease;
    z-index: 2;
}

.sidebar.open~.home-section {
    left: 200px;
    width: calc(100% - 200px);
}

.home-section .text {
    display: inline-block;
    color: #11101d;
    font-size: 25px;
    font-weight: 500;
    margin: 18px
}

.profile_card img {
    width: 100%;
    height: 350px;
    object-fit: cover;
}

.profile_card {
    background-color: white;
    overflow: hidden;
    border-radius: 12px;
    border: 1px solid #dddddd59;
    width: 100%;
    max-width: 24%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 24px;
}

.container {
    max-width: 1320px;
    margin: auto;
    width: 100%;
    padding: 60px 10px;
}

.profile_Cards_row {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin: 40px 0px;
    gap: 10px;
    justify-content: space-between;
}

.review_person i {
    font-size: 24px;
    margin: 5px 0px;
    color: var(--main_primery_color);
    cursor: pointer;

}

i.fa.fa-sort {
    color: #616161;
}

.review_person {
    display: flex;
    gap: 20px;
    justify-content: end;
    position: absolute;
    top: 82%;
    right: 20px;
}

.profile_data h5 {
    font-size: 18px;
    font-weight: 600;
    color: var(--title-color);
    line-height: 28px;
    margin: 0;
}

.profile_data p {
    font-size: 16px;
    font-weight: 400;
    color: var(--sub-title-color);
}

.home-section .inner_header h2 {
    font-size: 36px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--title-color);

}

.home-section .inner_header p {
    font-size: 16px;
    font-weight: 400;
    color: var(--sub-title-color);
    max-width: 1024px;
}

.home-section .inner_header h2 i.fa.fa-sliders {
    background: var(--main_primery_color);
    color: white;
    padding: 10px;
    border-radius: 40px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    cursor: pointer;
}



.profile_data {
    padding: 12px;
}

.plans-item:hover {
    box-shadow: 10px 10px 10px #dddddd96;
    border: 1px solid var(--main_primery_color);
}



.plans-item:first-child {
    margin-left: 0;
}

.plans-item {
    border: 1px solid #ddd;
    width: 100%;
    background-color: #fff;
    padding: 36px 40px 40px;
    border-radius: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    -webkit-transition: all 0.6s;
    transition: all 0.6s;
    margin-left: 20px;
}

.plans-item__name {
    margin-bottom: 24px;
    font-size: 22px;
    line-height: 1.1;
    font-weight: 500;
    color: var(--title-color);
}

.plans-item__price {
    margin-bottom: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.plans-item__price .price {
    margin-right: 8px;
    font-weight: 700;
    font-size: 42px;
    line-height: 48px;
    letter-spacing: -1.5px;
    white-space: nowrap;
    color: var(--title-color);
}

.plans-item__price .price-info {
    font-size: 14px;
    line-height: 20px;
    color: #636d74;
    padding-bottom: 5px;
}

.plans-item__trial {
    margin-bottom: 24px;
    font-size: 12px;
    line-height: 20px;
    padding: 0 6px;
    color: var(--main_primery_color);
    background-color: var(--main_primery_color)3b;
    border-radius: 5px;
    display: inline-block;
    font-weight: 500;
}

.plans-item__features-ttl {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 20px;
    color: #636d74;
}

.plans-item__features {
    margin-bottom: 24px;
    padding: 0 18px;
}

.plans-item__features li {
    margin-bottom: 10px;
    position: relative;
    padding-left: 0px;
    font-size: 14px;
    line-height: 20px;
}

.plans-tab {
    display: flex;
    margin: 40px 0;

}

.plans__tab-item.tab_item {
    max-width: 1320px;

    margin: auto;
}

.inner_var_Bar {
    display: flex;
    justify-content: space-between;
    align-items: end;
    background: white;
    padding: 8px 70px;
    box-shadow: 0px 0px 10px #ddd;
    border-bottom: 1px solid #ddd;
}

.manu_right {
    min-height: 34px;
}

.manu_right ul {
    gap: 20px;
    display: flex;
    margin: 0;
}

.manu_right ul li {
    list-style: none;
}

.manu_right ul li a {
    color: var(--sub-title-color);
    font-weight: 400;
    font-size: 14px;
    text-decoration: none;
}

.manu_right ul li a.active {
    border-bottom: 2px solid var(--main_primery_color);
    height: 26px;
    display: inline-block;
}

.inner_var_Bar h3 {
    color: var(--main_primery_color);
    font-size: 24px;
}


.Button_credit {
    padding: 10px 20px;
    border-radius: 30px;
    text-align: center;
    background-color: var(--main_primery_color);
    color: white !important;
}


/* ================profile css =================== */


a.back_button {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 400;
    color: var(--title-color);
    text-decoration: none;
    margin-bottom: 30px;
}

.account_page_row {
    display: flex;

}

.account_type {
    max-width: 420px;
    display: flex;
    flex-direction: column;
}


.acount_div h3 i.fa.fa-chevron-right {
    margin-left: auto;
    font-weight: 400;
}

.acount_div .active {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25rem;
    letter-spacing: 0.00625rem;
    background-color: #c9920117;
    color: var(--main_primery_color);
    padding: 8px 12px;
    border-radius: 1.375rem;
    margin: 0;
}


.acount_div h3 {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25rem;
    letter-spacing: 0.00625rem;
    color: var(--sub-title-color);
    padding: 8px 12px;
    border-radius: 1.375rem;
    margin: 0;
    border: 1px solid #dddddd78;
    background: #efefef;
}

.acount_div p {
    padding: 8px 24px;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 400;
    color: var(--sub-title-color);
}

.account_info h2 {
    display: block;
    margin-bottom: 12px;
    font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-seri;
    font-size: 2.125rem;
    font-weight: 600;
    line-height: 2.375rem;
    color: var(--title-color);
}


.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* The slider inside the switch */
.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

/* When checked, change background color */
input:checked+.slider {
    background-color: #3dc968;
}

/* Move the slider (thumb) to the right when checked */
input:checked+.slider:before {
    transform: translateX(26px);
}

/* Optional: Rounded corners for the switch */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.account_info {
    width: 100%;
    max-width: 720px;
    margin-left: auto;

}

.user_account_details {
    padding-bottom: 30px;
}

.user_account_details h4 {
    font-size: 16px;
    font-weight: 600;
    color: var(--title-color);
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user_account_details p {
    font-size: 16px;
    font-weight: 400;
    color: var(--sub-title-color);
}

span.Unverified {
    display: flex;
    align-items: center;
    gap: 24px;
    color: red;
}

span.Unverified i {
    color: var(--sub-title-color);
}

.user_account_details.border_row {
    padding: 30px 0px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin-bottom: 12px;
}



h4.Main_bg_title {
    top: -42px;
    white-space: nowrap;
    z-index: -1;
    display: inline;
    position: absolute;
    left: 0px;
    padding-left: 0px;
    color: #fffbef;
    font-size: 96px;
}

.inner_header {
    position: relative;
    padding-bottom: 20px;
}




.user_account_details a {
    text-decoration: none;
}

/* ================================Security & Privacy ================================ */

.verification p b {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    color: var(--sub-title-color);

}

.verification {
    margin-bottom: 30px;
}

.verification p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 400;
    color: var(--sub-title-color);
    margin-bottom: 8px;
}

.TwoFactor {
    font-size: 16px;
    font-weight: 400;
    color: var(--sub-title-color);
    margin-bottom: 30px;

}

.TwoFactor p {
    font-size: 16px;
    font-weight: 400;
    color: var(--sub-title-color);
    display: flex;
    align-items: center;
    gap: 5px;
}

.TwoFactor span {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    background-color: rgb(216, 0, 0);
    border-radius: 4px;
    padding: 5px 10px;
}

.user_account_details.border-top {
    padding-top: 30px;
    border-top: 1px solid #ddd;
}


.profile_section .inner_form {
    max-width: 200px;
    background: #303030;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    color: white;
}

input#upload {
    display: none;
}

.profile_section .inner_form label {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 12px;
    border: 1px solid;
    border-radius: 33px;
    margin-top: 12px;
    display: inline-block;
}

.profile_user_data {
    display: grid;
    align-items: self-start;
    grid-template-columns: 220px 1fr;
    gap: 30px;
}

.user_name h3 {
    font-size: 20px;
    font-weight: 600;
    color: #303030;
    line-height: 32px;
    text-transform: capitalize;
}

.user_name p {
    font-size: 16px;
    color: #616161;
    margin-bottom: 12px;
}

.profile_links {
    text-align: end;
    padding-top: 30px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: end;
}

.svg_icon_div svg.MuiSvgIcon-root.icon.jss5.jss32 {
    width: 32px;
    fill: #303030;
    cursor: pointer;
}

.svg_icon_div {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

i.fa.fa-ellipsis-h {
    font-size: 32px;
    color: #616161;
}



.inner_section_profile {
    max-width: 900px;
    margin: auto;
    background: white;

    border-radius: 12px;

}

.profile_points h5 {
    font-size: 16px;
    font-weight: 500;
    color: #303030;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.profile_points h5 span {
    font-size: 14px;
    color: var(--main_primery_color);
    font-weight: 500;
}

.text_input_row textarea {
    display: none;
    position: absolute;
    width: 100%;
    right: 0;
    background: transparent;
    border-radius: 8px;
    border: 1px solid var(--sub-title-color);
}

.text_input_row {
    font-size: 16px;
    color: #616161;

    margin-bottom: 30px;
}

input#ageRange {
    width: 100%;
}

.inner_form_upload input {
    display: none;
}

.inner_form_upload label {
    padding: 30px 35px;
    border: 1px solid #ddd;
    margin: 0px 0px 30px;
    border-radius: 12px;
    background: #dddddd47;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    font-size: 16px;
    color: #303030;
}

.VITALS_status svg {
    width: 24px;
}

.VITALS_status {
    display: flex;
}

.VITALS_status p {
    display: flex;
    align-items: center;
    gap: 8px;
}

.VITALS_status {
    display: flex;
    justify-content: space-between;
    padding-right: 40px;
    margin-bottom: 30px;
}



/* ===============================accodian css=========== */

.inner_content_filter .accordion {
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
}

.inner_content_filter .accordion-item {
    background-color: #f1f1f1;
    border-radius: 12px;
}

.inner_content_filter {
    overflow: auto;
    max-height: 98vh;
    padding: 20px;
}

.main_content_edits {}

.inner_content_filter .accordion-header {
    cursor: pointer;
    font-weight: bold;
    background-color: white;
    color: #303030;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 8px;
    background: #f4eeff;
    ;
    font-size: 16px;
    font-weight: 500;

}

.accordion-item {
    margin-bottom: 12px;
}

.inner_content_filter .accordion-content {
    padding: 20px 0px;
    display: none;
    background-color: white;

}

.inner_content_filter .active {
    display: block;
}

.filter_modal {
    position: fixed;
    top: 0;
    background: #000000db;
    width: 100%;
    height: 100vh;
    z-index: 99;
    backdrop-filter: blur(10px);
}

.filter_card {
    max-width: 550px;
    margin-left: auto;
    background: white;
    min-height: calc(100vh - 0px);
}

.inner_content_filter h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #303030;
    font-size: 20px;
    font-weight: 600;
}


.accordion-header::after {
    content: '\f078';
    font-size: 16px;
    transition: transform 0.3s ease;
    font-family: 'FontAwesome';
}

.active-header::after {
    transform: rotate(-180deg);
}

.change_location svg.MuiSvgIcon-root.icon.jss5.jss192.LocationIconStyled-sc-zdwxw5-1.gdwsMn {
    width: 24px;
    fill: #616161;
}

.change_location {
    justify-content: space-between;
    padding: 10px;
    display: flex;
    align-items: center;
    background: #f6f6f6;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 30px;
}


input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 8px;
    border-radius: 5px;
    background: var(--main_primery_color);
    outline: none;
    opacity: 0.9;
    transition: opacity 0.2s;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #dbb300;

    border-radius: 50%;
    cursor: pointer;
}


.footer_btns_modal button.apply {
    font-weight: 400;
    font-size: 14px;
    text-decoration: none;
    padding: 12px 20px;
    border-radius: 30px;
    text-align: center;
    background-color: var(--main_primery_color);
    color: white !important;
    border: none;
    width: 100%;
}

.footer_btns_modal button.reset {
    font-weight: 400;
    font-size: 14px;
    text-decoration: none;
    padding: 12px 20px;
    border-radius: 30px;
    text-align: center;
    background-color: #fff;
    color: var(--main_primery_color) !important;
    border: 1px solid var(--main_primery_color);
    width: 100%;
}

.footer_btns_modal {
    display: flex;
    gap: 10px;
}

.buy_now {
    font-weight: 400;
    font-size: 16px;
    text-decoration: none;
    padding: 12px 20px;
    border-radius: 30px;
    text-align: center;
    background-color: #fff;
    color: var(--main_primery_color) !important;
    border: 1px solid var(--main_primery_color);
    min-width: 150px;
    transition: all ease-in-out 0.5s;
}

.buy_now:hover {
    background-color: var(--main_primery_color);
    color: white !important;
    border: 1px solid var(--main_primery_color);
    cursor: pointer;
}

/* CHAT CSS START */
.chat_box {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    min-height: calc(100vh - 70px);
    margin: 0 auto;
    overflow: hidden;
    max-width: 1320px;
    margin: auto;
}

.header {
    height: 80px;
    width: 100%;
    border-bottom: 1px solid #ddd;
    display: grid;
    align-items: center;
    padding: 0 20px;
    grid-template-columns: 320px 1fr 150px;
}

.search-bar input[type="text"] {
    border-radius: 10px;
    border: 1px solid #ddd;
    width: 100%;
    height: 52px;
}

.wrapper {
    width: 100%;
    display: flex;
    flex-grow: 1;
    overflow: hidden;
}

.conversation-area {
    width: 340px;
    flex-shrink: 0;
}

.detail-area {
    border-left: 1px solid var(--border-color);
    margin-left: auto;
    padding: 30px 30px 0 30px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.chat-area {
    flex-grow: 1;
}

.search-bar {
    height: 80px;
    z-index: 3;
    position: relative;
    margin-left: 0px;
    padding: 10px 20px;
    align-items: flex-start;
    display: flex;
}


.search-bar input {
    height: 100%;
    width: 100%;
    display: block;
    background-color: transparent;
    border: none;
    color: var(--body-color);
    padding: 0 54px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.966 56.966' fill='%23c1c7cd'%3e%3cpath d='M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 25px 48%;
    font-family: var(--body-font);
    font-weight: 600;
    font-size: 15px;
}

.search-bar input::placeholder {
    color: var(--input-chat-color);
}

.logo {
    color: var(--theme-color);
    width: 38px;
    flex-shrink: 0;
}

.logo svg {
    width: 100%;
}

.user-settings {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: auto;
    flex-shrink: 0;
    gap: 20px;
}

.user-settings>*+* {
    margin-left: 14px;
}



.user-profile {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.settings {
    color: var(--settings-icon-color);
    width: 22px;
    height: 22px;
    flex-shrink: 0;
}

.conversation-area {
    border-right: 1px solid #ddd;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
}

.msg-profile {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 15px;
}

.msg-profile.group {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--border-color);
}

.msg-profile.group svg {
    width: 60%;
}

.msg {
    display: flex;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    transition: 0.2s;
    position: relative;
}

.msg:hover {
    background-color: var(--msg-hover-bg);
}

.msg.active {
    background: var(--active-conversation-bg);
    border-left: 4px solid var(--theme-color);
}

.msg.online:before {
    content: "";
    position: absolute;
    background-color: #23be7e;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    border: 2px solid var(--theme-bg-color);
    left: 50px;
    bottom: 19px;
}

.msg-username {
    margin-bottom: 4px;
    font-weight: 600;
    font-size: 16px;
    color: #303030;
}

.msg-detail {
    overflow: hidden;
}

.msg-content {
    font-weight: 500;
    font-size: 13px;
    display: flex;
}

.msg-message {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #616161;
    font-size: 14px;
}

.msg-date {
    font-size: 14px;
    color: var(--msg-date);
    margin-left: 3px;
}

.msg-date:before {
    content: "•";
    margin-right: 2px;
}

.add {
    position: sticky;
    bottom: 25px;
    background-color: var(--theme-color);
    width: 60px;
    height: 60px;
    border: 0;
    border-radius: 50%;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-plus'%3e%3cpath d='M12 5v14M5 12h14'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 28px;
    box-shadow: 0 0 16px var(--theme-color);
    margin: auto auto -55px;
    flex-shrink: 0;
    z-index: 1;
    cursor: pointer;
}

.overlay {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 340px;
    flex-shrink: 0;
    background: var(--overlay-bg);
    height: 80px;
}

.chat-area {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.chat-area-header {
    display: flex;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background: var(--chat-header-bg);
    border-bottom: 1px solid #ddd;
}

.chat-area-profile {
    width: 32px;
    border-radius: 50%;
    object-fit: cover;
}

.chat-area-title {
    font-size: 18px;
    font-weight: 600;
}

.chat-area-main {
    flex-grow: 1;
}

.chat-msg-img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.chat-msg-profile {
    flex-shrink: 0;
    position: relative;
}

.chat-msg-date {
    font-size: 14px;
    color: #616161;
    font-weight: 500;
    padding: 5px;
}

.chat-msg {
    display: flex;
    padding: 10px 20px 45px;
}

.chat-msg-content {
    margin-left: 12px;
    max-width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.chat-msg-text {
    background-color: var(--chat-text-bg);
    padding: 5px;
    border-radius: 20px 20px 20px 0;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 500;
}

.chat-msg-text+.chat-msg-text {
    margin-top: 10px;
}

.chat-msg-text {
    color: var(--chat-text-color);
}

.owner {
    flex-direction: row-reverse;
}

.owner .chat-msg-content {
    margin-left: 0;
    margin-right: 12px;
    align-items: flex-end;
}

.owner .chat-msg-text {
    background-color: var(--theme-color);
    color: #fff;
    border-radius: 20px 20px 0 20px;
}

.owner .chat-msg-date {
    left: auto;
    right: calc(100% + 12px);
}

.chat-msg-text img {
    max-width: 300px;
    width: 100%;
}

.chat-area-footer {
    display: flex;
    border-top: 1px solid #ddd;
    width: 100%;
    padding: 10px 20px;
    align-items: center;
    background-color: #fff;
    position: sticky;
    bottom: 0;
    left: 0;
}

.chat-area-footer svg {
    color: var(--settings-icon-color);
    width: 20px;
    flex-shrink: 0;
    cursor: pointer;
}

.chat-area-footer svg:hover {
    color: var(--settings-icon-hover);
}

.chat-area-footer svg+svg {
    margin-left: 12px;
}

.chat-area-footer input {
    border: none;
    color: var(--body-color);
    background-color: var(--input-bg);
    padding: 12px;
    border-radius: 6px;
    font-size: 15px;
    margin: 0 12px;
    width: 100%;
}

.chat-area-footer input::placeholder {
    color: var(--input-chat-color);
}



.detail-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.detail-subtitle {
    font-size: 12px;
    font-weight: 600;
    color: var(--msg-date);
}

.detail-button {
    border: 0;
    background-color: var(--button-bg-color);
    padding: 10px 14px;
    border-radius: 5px;
    color: var(--button-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    flex-grow: 1;
    font-weight: 500;
}

.detail-button svg {
    width: 18px;
    margin-right: 10px;
}

.detail-button:last-child {
    margin-left: 8px;
}

.detail-buttons {
    margin-top: 20px;
    display: flex;
    width: 100%;
}



.detail-changes {
    margin-top: 40px;
}

.detail-change {
    color: var(--detail-font-color);
    font-family: var(--body-font);
    font-weight: 600;
    font-size: 14px;
    border-bottom: 1px solid var(--border-color);
    padding: 14px 0;
    display: flex;
}

.detail-change svg {
    width: 16px;
    margin-left: auto;
}

.colors {
    display: flex;
    margin-left: auto;
}

.color {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    cursor: pointer;
}

.color.selected {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' stroke='%23fff' stroke-width='3' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1' viewBox='0 0 24 24'%3E%3Cpath d='M20 6L9 17l-5-5'/%3E%3C/svg%3E");
    background-size: 10px;
    background-position: center;
    background-repeat: no-repeat;
}

.color:not(:last-child) {
    margin-right: 4px;
}

.detail-photo-title {
    display: flex;
    align-items: center;
}

.detail-photo-title svg {
    width: 16px;
}

.detail-photos {
    margin-top: 30px;
    text-align: center;
}

.detail-photo-title {
    color: var(--detail-font-color);
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 20px;
}

.detail-photo-title svg {
    margin-right: 8px;
}

.detail-photo-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 6px;
    grid-row-gap: 6px;
    grid-template-rows: repeat(3, 60px);
}

.detail-photo-grid img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
    object-position: center;
}

.view-more {
    color: var(--theme-color);
    font-weight: 600;
    font-size: 15px;
    margin: 25px 0;
}

.follow-me {
    text-decoration: none;
    font-size: 14px;
    width: calc(100% + 60px);
    margin-left: -30px;
    display: flex;
    align-items: center;
    margin-top: auto;
    overflow: hidden;
    color: #9c9cab;
    padding: 0 20px;
    height: 52px;
    flex-shrink: 0;
    position: relative;
    justify-content: center;
}

.follow-me svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.follow-text {
    display: flex;
    align-items: center;
    transition: 0.3s;
}

.follow-me:hover .follow-text {
    transform: translateY(100%);
}

.follow-me:hover .developer {
    top: 0;
}

.developer {
    position: absolute;
    color: var(--detail-font-color);
    font-weight: 600;
    left: 0;
    top: -100%;
    display: flex;
    transition: 0.3s;
    padding: 0 20px;
    align-items: center;
    justify-content: center;
    background-color: var(--developer-color);
    width: 100%;
    height: 100%;
}

.developer img {
    border-radius: 50%;
    width: 26px;
    height: 26px;
    object-fit: cover;
    margin-right: 10px;
}

.dark-mode .search-bar input {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.966 56.966' fill='%236f7073'%3e%3cpath d='M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z'/%3e%3c/svg%3e");
}

.dark-mode .dark-light svg {
    fill: #ffce45;
    stroke: #ffce45;
}

.dark-mode .chat-area-group span {
    color: #d1d1d2;
}

.chat-area-group {
    flex-shrink: 0;
    display: flex;
}

.chat-area-group * {
    border: 2px solid var(--theme-bg-color);
}

.chat-area-group *+* {
    margin-left: -5px;
}

.chat-area-group span {
    width: 32px;
    height: 32px;
    background-color: var(--button-bg-color);
    color: var(--theme-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
}


/* CHAT CSS END */



/* ======================================= 102524 css=================================================== */

.choose_choise {
    display: flex;
    align-items: center;
    gap: 12px;
}

.choose_choise h5 {
    font-size: 16px;
    font-weight: 400;
    color: #303030;
}

input[type="checkbox"] {
    height: 18px;
    width: 18px;
    -webkit-appearance: none;
    appearance: none;
    border: 2px solid #ddd;
    /* Change border color */
    border-radius: 4px;
    /* Optional rounded corners */
    cursor: pointer;
    position: relative;

}

input[type="checkbox"]:checked::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 5px;
    width: 4px;
    height: 10px;
    border: solid var(--main_primery_color);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* Change border color on hover */
input[type="checkbox"]:hover {
    border-color: var(--main_primery_color);
    /* Change to desired hover color */
}


.ModalContentStyle {
    position: fixed;
    z-index: 999;
    top: 0;
    background: #00000061;
    width: 100%;
    height: 100vh;
    align-content: center;
    margin: auto;
    backdrop-filter: blur(10px);
}

.ModalContentScrollingWrapper {
    max-width: 650px;
    background: white;
    border-radius: 8px;
    padding: 20px;
    margin: auto;
    position: relative;
}

h4.ModalHeader {
    font-size: 20px;
    font-weight: 600;
    color: #303030;
    margin-bottom: 8px;
}

p.ModalTextStyle {
    font-size: 16px;
    font-weight: 400;
    color: #616161;
}

.choose_options {
    display: flex;
    flex-direction: column;
    margin: 24px 0px;
    gap: 12px;
}

.ModalContentScrollingWrapper i.fa.fa-times {
    position: absolute;
    right: 20px;
    top: 20px;
    color: #616161;
    cursor: pointer;
}

.btn_row_choise {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: end;
}

.btn_row_choise .Cancel {
    font-weight: 400;
    font-size: 14px;
    text-decoration: none;
    padding: 12px 30px;
    border-radius: 30px;
    text-align: center;
    background-color: #fff;
    color: var(--main_primery_color) !important;
    border: 1px solid var(--main_primery_color);
    cursor: pointer;

}

.btn_row_choise .Save {
    font-weight: 400;
    font-size: 14px;
    text-decoration: none;
    padding: 12px 30px;
    border-radius: 30px;
    text-align: center;
    background-color: var(--main_primery_color);
    color: white !important;
    border: none;
    cursor: pointer;
}


.inner_forms {
    display: flex;
    width: 100%;
    gap: 10px;
}

.inner_forms .change_location {
    width: 100%;
    height: 56px;
}

h6.title {
    margin-bottom: 12px;
    font-size: 16px;
    color: #303030;
    font-weight: 500;
}

.vitals_form {
    border-top: 1px solid #ddd;
    padding: 30px 0px;
    /* display: none; */
}

.laction_row p {
    margin: 2px 0px;
    color: #303030;
    font-weight: 500;
}

.laction_row h6 {
    color: #616161;
    font-weight: 400;
    margin: 0;
    font-size: 12px;
}

.acount_div a {
    color: #303030;
    text-decoration: none;
}

@media (max-width: 1200px) {

    .VITALS_status {
        flex-wrap: wrap;
    }

    .account_page_row {
        display: flex;
        flex-direction: column;
    }

    .account_type {
        max-width: 100%;
        display: flex;
        flex-direction: column;
    }


    .dating_connection .container .row:nth-child(2) {
        flex-direction: column-reverse;
    }

    .dating_connection .container .row:nth-child(4) {
        flex-direction: column-reverse;
    }


    .plans-tab {
        display: flex;
        margin: 20px 0px;
        flex-direction: column;
        gap: 30px;
    }

    .plans-item {
        margin-left: 0;
    }


    .store_links_images {
        position: absolute;
        bottom: 30px;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
    }


    .daing_content {
        max-width: 100%;
        margin: 30px auto;
    }

    .dating_connection {
        padding: 0;
    }

    .accodian_main_section {
        padding: 0px 0px;
        background: #000;
        margin-top: 0px;
    }

    .store_links img {
        max-width: 150px !important;
    }

    .dating_connection img {
        width: 100%;
        max-width: 100%;
        max-height: 450px;
        object-fit: cover;
        margin-bottom: 30px;
    }


    .profile_card {

        max-width: 48%;
    }

    .inner_var_Bar img {
        max-width: 160px;
    }

    .container {
        max-width: 1320px;
        margin: auto;
        width: 100%;
        padding: 60px 40px;
    }

    .sidebar.open~.home-section .inner_var_Bar img {
        max-width: 130px;
    }

    .inner_var_Bar {
        padding: 12px 12px;
    }


}

@media (max-width: 780px) {
    .conversation-area {
        display: none;
    }

    .search-bar {
        margin-left: 0;
        flex-grow: 1;
    }

    .search-bar input {
        padding-right: 10px;
    }
}


@media (max-width: 767px) {

    .slider:before {
        height: 20px;
        width: 20px;
        left: 4px;
        bottom: 3px;
    }

    .switch {
        width: 50px;
        height: 26px;
    }

    .inner_section_dating h2 {
        font-size: 42px;
        line-height: initial;
    }

    .main_section_dating {
        height: 650px;
        background-position: 32% 50%;

    }

    .inner_section_dating p {
        font-size: 22px;
        line-height: 28px;
    }

    .store_links_images {
        bottom: auto;
        top: 63%;
    }

    h4.Main_bg_title {
        top: -15px;
        font-size: 69px;
    }

    .profile_card {
        max-width: 100%;
    }

    .chat_box {
        min-height: calc(100vh - 195px);
    }


    .sidebar.open~.home-section {
        left: 0px;
        width: calc(100% - 0px);
    }

    .sidebar.open {
        width: 100%;
    }

    .inner_var_Bar {
        flex-direction: column;
    }

    .profile_user_data {
        grid-template-columns: auto;

    }

    .inner_var_Bar img {
        max-width: 200px;
        margin-bottom: 20px;
    }

    .inner_var_Bar {
        padding: 10px 12px 30px;
    }

    .inner_var_Bar {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .manu_right ul {
        gap: 20px;
        display: flex;
        margin: 0;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
    }

    .container {
        padding: 20px;
    }

    .profile_section .inner_form {
        max-width: 100%;
    }

    .VITALS_status {
        display: flex;
        justify-content: space-between;
        padding-right: 0;
        margin-bottom: 30px;
        flex-direction: column;
    }

}

@media (max-width: 420px) {
    .sidebar li .tooltip {
        display: none;
    }
}